body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background-color: rgb(244, 245, 247) !important;
}

html,
body {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  color: white;
  text-decoration: none;
  div.MuiButtonBase-root {
    border-left: 2px solid transparent;
  }
  &.active-link {
    color: rgb(173, 173, 173);
    & * {
      font-weight: bold;
    }
    div.MuiButtonBase-root {
      border-color: white !important;
    }
  }
}
